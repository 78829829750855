import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'sgc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  currentUser!: User;
  userAcronym!: string;

  readonly AVATAR_COLOR = '#ba40ef';

  constructor(private userService: UserService, private router: Router) { }

  ngOnInit(): void {
    this.onGetUser();
  }

  logout(): void {
    this.userService.logout();
    this.router.navigate(['/login']);
  }


  onGetUser(): void {
    this.userService.getUser().subscribe({
      next: (response) => {
        if (response) {
          this.currentUser = response;

          const fullName = response.Nome.split(' ')

          const firstName = fullName[0];
          const lastName = fullName[fullName.length - 1];

          const firstletter = firstName[0].toLocaleUpperCase();
          const lastletter = lastName[0].toLocaleUpperCase();

          this.userAcronym = `${firstletter}${lastletter}`;
        }
      },
      error: () => null
    });

  }
}
