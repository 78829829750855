import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Menu, MenuItem } from 'src/app/models/others/menu.interface';

@Component({
  selector: 'sgc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  private readonly TAG_DATA = 'menu';

  constructor(private router: Router) { }

  menu: Array<Menu> = [
    {
      icon: 'pi pi-home',
      tooltip: '',
      selected: false,
      route: '/',
    },
    {
      icon: 'pi pi-book',
      tooltip: '',
      selected: false,
      route: '/demandas',
    },
    {
      icon: 'pi pi-cog',
      tooltip: '',
      selected: false,
      activeSubMenu: false,
      subMenu: [
        {
          label: 'Etapas',
          icon: 'pi pi-sort-alt',
          tooltip: '',
          route: '/etapa/lista',
        },
        { 
          label: 'Checklist', 
          icon: 'pi pi-check-square',
          tooltip: '',
          route: '/checklist/templates'
        },
        { 
          label: 'Itens do Checklist', 
          icon: 'pi pi-check-circle',
          tooltip: '',
          route: '/checklist/template/layout'                    
        },
        { 
          label: 'Fluxo', 
          icon: 'pi pi-arrow-right-arrow-left',
          tooltip: '',
          route: '/transition-flow/fluxo'
        }
      ],
    },
    {
      icon: 'pi pi-clock',
      tooltip: '',
      selected: false,
      route: '/tratamento-prazos',
    },
    {
      icon: 'pi pi-exclamation-circle',
      tooltip: '',
      selected: false,
      route: '/tratamento-diligencias',
    },
    {
      icon: 'pi pi-print',
      tooltip: '',
      selected: false,
      route: '/relatorio-quantitativo',
    },
    {
      icon: 'pi pi-calendar',
      tooltip: 'Agendamento',
      selected: false,
      route: '/agendamento',
    }
  ];

  onSelectItem(menu: Menu): void {
    this.onResetMenu();

    menu.selected = !menu.selected;

    if (!menu.subMenu) {
      this.router.navigate([menu.route]);
    } else {
      menu.activeSubMenu = !menu.activeSubMenu;
    }
  }

  onSelectSubItem(menu: MenuItem): void {
    this.router.navigate([menu.route]);
  }

  onResetMenu(): void {
    this.menu.map((item) => (item.selected = false));
  }

  onResetSubMenu(): void {
    this.menu.map((item) => (item.activeSubMenu = false));
  }

  @HostListener('document:click', ['$event'])
  handleEvent(event: any): void {
    const menuElement = event?.target?.attributes?.data?.value;

    if (menuElement !== this.TAG_DATA) {
      this.menu.map(() => this.onResetSubMenu());
    }
  }
}
