<div class="c-header">
  <img class="c-header__logo" src="assets/images/SP-menu.png" alt="" />

  <span class="c-header__title">SGRI - SGC Sistema de Gestão de Convênio</span>

  <div class="c-header__profile">
    <p-avatar [label]="userAcronym" [style]="{ 'background-color': AVATAR_COLOR }"></p-avatar>

    <div class="c-header__profile--info">
      <span class="name">{{ currentUser.Nome }} - </span>
      <span class="function">{{ currentUser.Funcao }}</span>
    </div>

    <i class="c-header__profile--logout pi pi-sign-out" (click)="logout()"></i>
  </div>
</div>