import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { NgModule } from '@angular/core';
import { NotificationsComponent } from '../core/services/notifications/notifications.component';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AvatarModule } from 'primeng/avatar';
import { MenuComponent } from './components/menu/menu.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AtividadeEventsComponent } from './components/atividade-events/atividade-events.component';
import { TableModule } from 'primeng/table';
import { UsuarioSecretariaComboComponent } from './components/usuario-secretaria-combo/usuario-secretaria-combo.component';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { AnexosComponent } from './components/anexos/anexos.component';
import { EtapaComboComponent } from './components/combos/etapa-combo/etapa-combo.component';
import { ResponsavelOrdemServicoComboComponent } from './components/combos/responsavel-ordem-servico-combo/responsavel-ordem-servico-combo.component';
import { SituacaoComboComponent } from './components/combos/situacao-combo/situacao-combo.component';
import { SituacaoOrdemServicoComboComponent } from './components/combos/situacao-ordem-servico-combo/situacao-ordem-servico-combo.component';
import { SituacaoParcelaPrestacaoComboComponent } from './components/combos/situacao-parcela-prestacao-combo/situacao-parcela-prestacao-combo.component';
import { SolicitanteComboComponent } from './components/combos/solicitante-combo/solicitante-combo.component';
import { PortifolioComboComponent } from './components/combos/portifolio-combo/portifolio-combo.component';
import { NaturezaComboComponent } from './components/combos/natureza-combo/natureza-combo.component';
import { FiltroListComponent } from './components/filtro-list/filtro-list.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TreeModule } from 'primeng/tree';
import { MultiSelectModule } from 'primeng/multiselect';
import { AtividadeStatusMultiselectComponent } from './components/atividade-status-multiselect/atividade-status-multiselect.component';
import { AtividadeEtapaMultiselectComponent } from './components/atividade-etapa-combo-check/atividade-etapa-multiselect.component';
import { HierarquiaComboCheckComponent } from './components/hierarquia-combo-check/hierarquia-combo-check.component';
import { FieldsetModule } from 'primeng/fieldset';
import { AtividadeParcelaDetailComponent } from './components/atividade-parcela-detail/atividade-parcela-detail.component';
import { GanttConsolidadoComponent } from './components/gantt-consolidado/gantt-consolidado.component';
import { AtividadeFormComponent } from './components/atividade-form/atividade-form.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CicloDeVidaComponent } from './components/ciclo-de-vida/ciclo-de-vida.component';
import { TimelineModule } from 'primeng/timeline';
import { GanttComponent } from './components/gantt/gantt.component';
import { TreeSelectModule } from 'primeng/treeselect';
import { HierarquiaComboComponent } from './components/hierarquia-combo/hierarquia-combo.component';
import { AtividadeStatusComboComponent } from './components/atividade-status-combo/atividade-status-combo.component';
import { AtividadeTipoMultiselectComponent } from './components/atividade-tipo-multiselect/atividade-tipo-multiselect.component';
import { PerfilMultiselectComponent } from './components/perfil-multiselect/perfil-multiselect.component';

const resources = [
  NotificationsComponent,
  HeaderComponent,
  FooterComponent,
  MenuComponent,
  SubHeaderComponent,
  BreadcrumbComponent,
  AtividadeEventsComponent,
  UsuarioSecretariaComboComponent,
  AnexosComponent,
  EtapaComboComponent,
  ResponsavelOrdemServicoComboComponent,
  SituacaoComboComponent,
  SituacaoOrdemServicoComboComponent,
  SituacaoParcelaPrestacaoComboComponent,
  SolicitanteComboComponent,
  PortifolioComboComponent,
  NaturezaComboComponent,
  FiltroListComponent,
  AtividadeStatusMultiselectComponent,
  AtividadeEtapaMultiselectComponent,
  HierarquiaComboCheckComponent,
  AtividadeParcelaDetailComponent,
  GanttConsolidadoComponent,
  AtividadeFormComponent,
  CicloDeVidaComponent,
  GanttComponent,
  HierarquiaComboComponent,
  AtividadeStatusComboComponent,
  AtividadeTipoMultiselectComponent,
  PerfilMultiselectComponent
];

@NgModule({
  imports: [
    CommonModule,
    ToastModule,
    MessagesModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    BreadcrumbModule,
    TableModule,
    DropdownModule,
    FileUploadModule,
    DialogModule,
    ConfirmDialogModule,
    MultiSelectModule,
    TreeModule,
    FieldsetModule,
    FullCalendarModule,
    TimelineModule,
    TreeSelectModule
  ],
  declarations: [...resources],
  providers: [MessageService],
  exports: [...resources],
})
export class SharedModule { }
